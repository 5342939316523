"use client";

import useUiActions from "@/hooks/ui/useUiActions";

const GoUp = () => {
    const { goUp } = useUiActions();

    const handleScroll = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    if (!goUp) return <></>;

    return (
        <button
            type="button"
            onClick={handleScroll}
            className={`${goUp ? "flex-center" : "hidden"} fixed bottom-4 right-6 bg-default hover:bg-defaultDark size-10 rounded-full cursor-pointer`}
            aria-label="GoUp"
        >
            <i className="fa fa-arrow-up text-fontMain text-2xl" />
        </button>
    );
};

export default GoUp;
