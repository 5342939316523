import type { Locale } from "@/redux/slices/ui/types";

export const isArrayEqual = <T>(array1: T[], array2: T[]): boolean => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) return false;

    const sortedArray1 = [...array1].sort((a, b) => {
        if (typeof a === "string" && typeof b === "string") return a.localeCompare(b);

        return String(a).localeCompare(String(b));
    });

    const sortedArray2 = [...array2].sort((a, b) => {
        if (typeof a === "string" && typeof b === "string") return a.localeCompare(b);

        return String(a).localeCompare(String(b));
    });

    if (JSON.stringify(sortedArray1) !== JSON.stringify(sortedArray2)) return false;

    return true;
};

export const isObjectEqual = <T extends Record<string, unknown>>(object1: T, object2: T): boolean => {
    if (!object1 || !object2) return true;

    for (const key of Object.keys(object1)) {
        if (
            (typeof object1[key] === "string" && typeof object2[key] === "string") ||
            (typeof object1[key] === "object" && typeof object2[key] === "string") ||
            (typeof object1[key] === "string" && typeof object2[key] === "object")
        ) {
            if (!isValueEqual(object1[key], object2[key])) return false;
            continue;
        }

        if (typeof object1[key] === "object" && typeof object2[key] === "object") {
            if (Array.isArray(object1[key]) && Array.isArray(object2[key])) {
                if (!isArrayEqual(object1[key] as T[], object2[key] as T[])) return false;
                continue;
            }

            if (!isObjectEqual(object1[key] as T, object2[key] as T)) return false;
            continue;
        }

        if (typeof object1[key] === "boolean" && typeof object2[key] === "boolean") {
            if (object1[key] !== object2[key]) return false;
            continue;
        }

        if (typeof object1[key] === "number" && typeof object2[key] === "number") {
            if (object1[key] !== object2[key]) return false;
        }
    }

    return true;
};

export const isValueEqual = <T>(value1: T, value2: T): boolean => {
    if (
        (typeof value1 === "string" && typeof value2 === "string") ||
        (typeof value1 === "object" && typeof value2 === "string") ||
        (typeof value1 === "string" && typeof value2 === "object")
    ) {
        return value1 === value2;
    }

    return true;
};

const getLanguageCountry = (
    locale: string
): {
    lang: string;
    country: string;
} => {
    try {
        const country: Record<Locale, string> = {
            de: "germany",
            es: "spain",
            fr: "france",
            it: "italy",
            pt: "portugal",
            ru: "rusian",
            en: "united-states",
            cn: "china",
            jp: "japan"
        };

        const countries = Object.entries(country);
        const [key, value] = countries.find(([name]) => name === locale) as string[];

        return {
            lang: key.includes("-") ? key.split("-")[1].toLowerCase() : key,
            country: value
        };
    } catch {
        return {
            lang: "en",
            country: "united-states"
        };
    }
};

export const getLanguageFlag = (locale: string): string => {
    const languageCountry = getLanguageCountry(locale);

    return `/images/flags/${languageCountry.lang}.png`;
};

export function getTableCol(array: string[], selectedCol: number, tablePart: "head" | "body", isDesktop: boolean): string[] {
    if (isDesktop) return array;
    if (tablePart === "head") return [array[0], array[selectedCol]];

    return array.filter((_, index) => index === selectedCol - 1);
}
