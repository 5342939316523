"use client";

import type { Params } from "@/lib/routes/types";
import { useParams, usePathname } from "next/navigation";
import useParamsServer from "./useParamsServer";

const REGEX_IS_DASHBOARD = /dashboard?/;
// const REGEX_GUILD_ID_ = /dashboard\/(\d{17,19})?/;
// const REGEX_BOT_TYPE = /dashboard\/\d{17,19}\/([1-4]|custom)?(?:\/\w+)?\/?$/;

const useParamsClient = () => {
    const params = useParams<Params>();
    const pathname = usePathname();

    const isDashboard = REGEX_IS_DASHBOARD.test(pathname);
    const { isGuildId, isBotId, guildId, botId } = useParamsServer(params);

    return {
        isDashboard,
        isGuildId,
        isBotId,
        url: pathname,
        lang: params.lang,
        guildId,
        botId
    };
};

export default useParamsClient;
