import type { Dictionaries, UiLanguage, UiLanguageGlobal, UiLanguageOnly } from "@/redux/slices/ui/types";
import useTranslationClient from "./hooks/useCustomTranslationClient";
import useTranslationServer from "./hooks/useCustomTranslationServer";

const dictionaries: Dictionaries = {
    common: async () => import("./dictionaries/common.json").then(module => module.default),
    es: async () => import("./dictionaries/es.json").then(module => module.default),
    de: async () => import("./dictionaries/de.json").then(module => module.default),
    fr: async () => import("./dictionaries/fr.json").then(module => module.default),
    it: async () => import("./dictionaries/it.json").then(module => module.default),
    en: async () => import("./dictionaries/en.json").then(module => module.default),
    pt: async () => import("./dictionaries/pt.json").then(module => module.default),
    jp: async () => import("./dictionaries/jp.json").then(module => module.default),
    ru: async () => import("./dictionaries/ru.json").then(module => module.default),
    cn: async () => import("./dictionaries/cn.json").then(module => module.default)
};

export const getLanguageJson = async (locale = "en"): Promise<UiLanguage> => {
    const commonJson = (await dictionaries.common()) as UiLanguageGlobal;
    let languageJson = (await dictionaries[locale ?? "en"]()) as UiLanguageOnly;

    if (!languageJson) {
        languageJson = (await dictionaries.en()) as UiLanguageOnly;
    }

    return {
        ...commonJson,
        ...languageJson
    };
};

export { useTranslationClient as useTranslation, useTranslationServer };
