"use client";

import { useParamsClient } from "@/hooks/params";
import { getLanguageFlag } from "@/utils/utils";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { locales } from "../../../i18n.config.cjs";

const HeaderMenuLanguage = () => {
    const router = useRouter();
    const { url, lang } = useParamsClient();

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const parseName = (name: string) => (name.includes("-") ? name.split("-")[1] : name);

    const handleToggle = () => {
        setIsOpen(prev => !prev);
    };

    const handleSelect = (itemId: string) => {
        if (!url) return "/";

        const segments = url.split("/");
        segments[1] = itemId;

        router.replace(segments.join("/"));
    };

    useEffect(() => {
        const handleClickDocument = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickDocument);
        return () => {
            document.removeEventListener("click", handleClickDocument);
        };
    }, [menuRef]);

    return (
        <div
            className="relative inline-block text-left"
            ref={menuRef}
        >
            <div>
                <button
                    type="button"
                    className="flex flex-row items-center justify-center lg:justify-start gap-2 px-4 py-2 rounded-md"
                    onClick={handleToggle}
                >
                    <Image
                        className="w-7 h-4 rounded-sm mr-2 cursor-pointer"
                        src={getLanguageFlag(lang)}
                        alt="Language icon"
                        width={32}
                        height={20}
                        priority
                    />
                </button>
            </div>
            <div
                className={`${
                    isOpen ? "block" : "hidden"
                } scrollbar-select origin-top-right absolute right-2 mt-2 min-w-14 w-max min-h-8 max-h-36 rounded-md shadow-lg bg-[#202226] focus:outline-none z-10 overflow-hidden overflow-y-auto border-2 border-solid border-tertiary`}
            >
                <div
                    className="px-3 py-2 flex flex-col justify-center items-center gap-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {locales
                        .filter(item => item !== lang)
                        .map(item => (
                            <button
                                key={item}
                                type="button"
                                className="flex flex-row flex-nowrap justify-between items-center w-full px-2 py-1 text-white hover:bg-[#282E33] rounded-md"
                                role="menuitem"
                                onClick={() => handleSelect(item)}
                            >
                                <Image
                                    className="w-6 h-4 rounded-sm mr-2 cursor-pointer"
                                    src={getLanguageFlag(item)}
                                    alt="Language icon"
                                    width={32}
                                    height={20}
                                    priority
                                />
                                <span className="cursor-pointer font-semibold text-sm">{parseName(item).toUpperCase()}</span>
                            </button>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default HeaderMenuLanguage;
