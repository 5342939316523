import { useEffect, useState } from "react";

const useWindow = () => {
    const [isDesktop, setIsDesktop] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            setIsDesktop(screenWidth >= 640);
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isDesktop]);

    return {
        isDesktop
    };
};

export default useWindow;
