"use client";

import { useParamsClient } from "@/hooks/params";
import routes from "@/lib/routes";
import { useTranslation } from "@/translation/i18n";
import Image from "next/legacy/image";
import Link from "next/link";

const Footer: React.FC = () => {
    const { translate } = useTranslation();
    const { lang } = useParamsClient();

    return (
        <div className="grid grid-cols-1 lg:grid-cols-footer gap-12 justify-center items-center w-screen py-8 px-12 border-t-[2px_solid_#ffffff05]">
            <picture className="flex flex-col lg:flex-row justify-center items-center col-span-2 lg:col-span-1">
                <Image
                    src="https://imgur.com/y78lOIp.png"
                    className=""
                    width={100}
                    height={100}
                    alt="Kenabot"
                    priority
                />
                <h2 className="text-white text-2xl font-bold">KENABOT</h2>
            </picture>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-0 w-full col-span-2 lg:col-span-1">
                <div className="flex flex-col items-center">
                    <h3 className="text-white text-base uppercase font-bold mb-2">{translate("footer.links.title")}</h3>
                    {routes.footer.links.map(item => (
                        <Link
                            key={item.name}
                            href={item.external ? item.path : `/${lang}${item.path}`}
                            target={item.external ? "_blank" : ""}
                            rel={item.external ? "noopener noreferrer" : ""}
                            className="w-max"
                        >
                            <span className="cursor-pointer text-[#9ca3af] hover:text-white">{translate(item.name)}</span>
                        </Link>
                    ))}
                </div>
                <div className="flex flex-col items-center">
                    <h3 className="text-white text-base uppercase font-bold mb-2">{translate("footer.kenabot.title")}</h3>
                    {routes.footer.kenabot.map(item => (
                        <Link
                            key={item.name}
                            href={item.external ? item.path : `/${lang}${item.path}`}
                            target={item.external ? "_blank" : ""}
                            rel={item.external ? "noopener noreferrer" : ""}
                            className="w-max"
                        >
                            <span className="cursor-pointer text-[#9ca3af] hover:text-white">{translate(item.name)}</span>
                        </Link>
                    ))}
                </div>
                <div className="flex flex-col items-center">
                    <h3 className="text-white text-base uppercase font-bold mb-2">{translate("footer.politics.title")}</h3>
                    {routes.footer.politics.map(item => (
                        <Link
                            key={item.name}
                            href={item.external ? item.path : `/${lang}${item.path}`}
                            target={item.external ? "_blank" : ""}
                            rel={item.external ? "noopener noreferrer" : ""}
                            className="w-max"
                        >
                            <span className="cursor-pointer text-[#9ca3af] hover:text-white">{translate(item.name)}</span>
                        </Link>
                    ))}
                </div>
            </div>
            <span className="w-full flex flex-col sm:flex-row justify-center items-center gap-2 col-span-2">
                <a
                    href="mailto:hello@kenabot.xyz"
                    className="text-white text-left"
                >
                    hello@kenabot.xyz
                </a>
                <span className="text-[#ffffff80] sm:!block !hidden">|</span>
                <span className="text-[#ffffff90] text-center">
                    {translate("footer.copyright")?.replace("{year}", `${new Date().getFullYear()}`)}
                </span>
            </span>
        </div>
    );
};

export default Footer;
