"use client";

import Footer from "@/components/Footer/Footer";
import GoUp from "@/components/GoUp/GoUp";
import Header from "@/components/Header/Header";
import { useParamsClient } from "@/hooks/params";
import { useTranslation } from "@/translation/i18n";

const GlobalLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { hasLanguage } = useTranslation();
    const { isDashboard } = useParamsClient();

    if (!hasLanguage) return <></>;

    return (
        <main className="w-full h-full">
            <Header />
            {children}
            {!isDashboard && <Footer />}
            {/* <Loader /> */}
            <GoUp />
        </main>
    );
};

export default GlobalLayout;
