import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
    hideLoaderActionCreator,
    loadLanguageActionCreator,
    showLoaderActionCreator,
    toggleGoUpActionCreator
} from "@/redux/slices/ui/slice";
import type { UiState } from "@/redux/slices/ui/types";

const useUiActions = () => {
    const dispatch = useAppDispatch();
    const ui = useAppSelector(state => state.ui);

    const toggleGoUp = (payload: UiState["goUp"]) => {
        dispatch(toggleGoUpActionCreator(payload));
    };

    const showLoader = (payload: UiState["loader"]) => {
        dispatch(showLoaderActionCreator(payload));
    };

    const hideLoader = () => {
        dispatch(hideLoaderActionCreator());
    };

    const loadLanguage = (payload: UiState["language"]) => {
        dispatch(loadLanguageActionCreator(payload));
    };

    return {
        ...ui,
        toggleGoUp,
        showLoader,
        hideLoader,
        loadLanguage
    };
};

export default useUiActions;
