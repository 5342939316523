import type { UiLanguage } from "@/redux/slices/ui/types";
import { getLanguageJson } from "../i18n";

const useCustomTranslationServer = async (locale: string) => {
    const languageJson = await getLanguageJson(locale);

    const hasLanguage = !!languageJson["error.title"];

    const translate = (key: keyof UiLanguage) => languageJson[key];

    return {
        hasLanguage,
        translate
    };
};

export default useCustomTranslationServer;
