"use client";

import { useParamsClient } from "@/hooks/params";
import useWindow from "@/hooks/ui/useWindow";
import Image from "next/legacy/image";
import Link from "next/link";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import HeaderMenuLanguage from "../HeaderMenuLanguage/HeaderMenuLanguage";
// import HeaderMenuUser from "../HeaderMenuUser/HeaderMenuUser";

const Header: React.FC = () => {
    const { isGuildId, lang } = useParamsClient();
    const { isDesktop } = useWindow();

    return (
        <header className={`h-20 ${isGuildId && isDesktop ? "" : "border-b-2 border-secondary"}`}>
            <div
                className={`${isGuildId ? "mx-2 justify-between" : "max-w-screen-2xl m-auto"} justify-between flex flex-nowrap items-center text-base`}
            >
                <Link href={`/${lang}/`}>
                    <Image
                        className="cursor-pointer"
                        src="/images/kenaLogo/default.png"
                        alt="Picture Kena"
                        width={75}
                        height={75}
                        priority
                    />
                </Link>
                <div className="flex flex-nowrap items-center gap-0 flex-row-reverse sm:gap-5 sm:flex-row">
                    <HeaderMenu />
                    {/* <HeaderMenuUser /> */}
                    <HeaderMenuLanguage />
                </div>
            </div>
        </header>
    );
};

export default Header;
