import type { Params } from "@/lib/routes/types";

const REGEX_GUILD_ID = /^(\d{17,19})/;
const REGEX_BOT_ID = /^([1-4]|custom)/;

const useParamsServer = (props: Params) => {
    const isGuildIdValid = REGEX_GUILD_ID.test(props?.guildId ?? "");
    const isBotIdValid = REGEX_BOT_ID.test(props?.botId ?? "");

    return {
        isDashboard: false,
        isGuildId: isGuildIdValid,
        isBotId: isBotIdValid,
        url: undefined,
        lang: props.lang,
        guildId: props?.guildId,
        botId: props?.botId
    };
};

export default useParamsServer;
